<template>
  <div class="page">
    <div class="banner">
      <img src="./img/banner.png" />
    </div>
    <!-- 不可报名！！！！！！！！！ -->
    <div
      class="empty"
      v-if="
        this.form.canApply == null ||
        (this.form.canApply == '0' && !this.form.childrenInfoFormList[0].id)
      "
    >
      <img src="./img/empty.png" alt="" />
      <div class="message">
        当前不在报名期<br />如有需要请关注报名公告及时报名！
      </div>
    </div>
    <!-- 可以报名！！！！！！！！！ -->
    <div v-else>
      <div class="main-title-box">
        <div class="title">自动预约:</div>
        <van-switch
          :disabled="disabled"
          @change="switchChange"
          v-model="checked"
          size="24px"
        />
        <div class="status">{{ checked ? "开启" : "关闭" }}</div>
        <div class="txt" @click="showDialog = true">报名说明</div>
        <img src="./img/message.png" @click="showDialog = true" />
      </div>
      <div class="signUpBox">
        <div class="label">当天乘车状态:</div>
        <div class="status">
          <span class="green" v-if="signNum > 0"> 已签到{{ signNum }}人</span>
          <span class="grey" v-else-if="signUpStatus"
            >需乘坐，{{ form.batchName }}</span
          >
          <span class="blue" v-else>当天不乘坐</span>
        </div>
        <div
          class="applyAgain"
          v-if="
            !signUpStatus && showBtn && this.form.childrenInfoFormList[0].id
          "
          @click="reEnroll"
        >
          我要乘车
        </div>
      </div>
      <div class="btnsBox" v-if="signUpStatus && showBtn">
        <div class="editBtn" @click="editEnrollment">修改班次</div>
        <div class="cancelBtn" @click="cancelRegistration">取消乘车</div>
      </div>
      <div
        class="edit-box"
        v-if="
          this.form.canApply == '1' &&
          this.form.childrenInfoFormList.length >= 1 &&
          !editFlag
        "
        @click="editFlag = true"
      >
        <div class="edit-box-left">编辑报名信息</div>
        <div class="edit-box-right">
          <img src="./img/edit.png" />
        </div>
      </div>
      <!-- 编辑报名信息 -->
      <div class="editInfo" v-if="editFlag">
        <div class="inputBox" style="margin-top: 0">
          <div class="inputBox-left">家长姓名</div>
          <div
            :class="[
              'inputBox-right',
              [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
            ]"
          >
            <input
              v-model="form.parentsName"
              type="text"
              placeholder="请填写家长姓名"
            />
          </div>
        </div>
        <div class="inputBox">
          <div class="inputBox-left">家长电话</div>
          <div
            :class="[
              'inputBox-right',
              [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
            ]"
          >
            <input
              v-model="form.parentsPhone"
              type="text"
              placeholder="请填写家长电话"
            />
          </div>
        </div>
        <div v-for="(item, index) in form.childrenInfoFormList" :key="index">
          <div class="inputBox">
            <div class="inputBox-left">孩子姓名</div>
            <div
              :class="[
                'inputBox-right',
                [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
              ]"
            >
              <input
                v-model="item.childrenName"
                type="text"
                placeholder="请填写孩子姓名"
              />
            </div>
            <img
              v-if="index > 0"
              class="removeIcon"
              src="./img/remove.png"
              @click="removeItem(index)"
            />
          </div>
          <div class="inputBox">
            <div class="inputBox-left">孩子班级</div>
            <div
              :class="[
                'inputBox-right',
                [form.childrenInfoFormList.length == 1 ? 'pr-28' : 'pr-78'],
              ]"
            >
              <input
                v-model="item.childrenClass"
                type="text"
                placeholder="请填写孩子班级,例101"
              />
            </div>
            <img
              v-if="index > 0"
              class="removeIcon"
              src="./img/remove.png"
              @click="removeItem(index)"
            />
          </div>
        </div>
        <div class="inputBox" @click="addOption()">
          <div class="add-left">
            <img src="./img/add.png" />
          </div>
          <div class="add-right">添加更多学生信息</div>
        </div>
        <div
          class="inputBox"
          @click="showPicker(index)"
          v-for="(item, index) in form.weekList"
          :key="index"
        >
          <div class="left">{{ item.label }}</div>
          <div class="center">
            <div class="center-l"></div>
            <div class="center-r">{{ item.batchName || "请选择班次" }}</div>
          </div>
          <div class="right"><img src="./img/down-arrow.png" alt="" /></div>
        </div>
        <div class="submitBtn" @click="submit()">提交信息</div>
      </div>
      <!-- 查看信息 -->
      <div class="cheackInfo" v-if="!editFlag">
        <div class="item">
          <div class="item-left">家长姓名</div>
          <div class="item-right">{{ form.parentsName }}</div>
        </div>
        <div class="item">
          <div class="item-left">家长电话</div>
          <div class="item-right">{{ form.parentsPhone }}</div>
        </div>
        <div v-for="(item, index) in form.childrenInfoFormList" :key="index">
          <div class="item">
            <div class="item-left">孩子姓名</div>
            <div class="item-right">{{ item.childrenName }}</div>
          </div>
          <div class="item">
            <div class="item-left">孩子班级</div>
            <div class="item-right">{{ item.childrenClass }}</div>
          </div>
        </div>
        <div
          class="item"
          v-for="(item1, index1) in form.weekList"
          :key="index1"
        >
          <div class="item-left">{{ item1.label }}</div>
          <div class="item-right">
            <div class="item-right-1"></div>
            <div class="item-right-r">
              {{ item1.batchName }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 报名说明弹框 -->
    <v-dialog
      :showCancelButton="false"
      :showConfirmButton="false"
      v-model="showDialog"
      :closeOnClickOverlay="true"
    >
      <div class="messageBox">
        <div class="title">报名说明</div>
        <div class="content">
          1、用户参与红领巾专线下午场次的报名需要先填写家长和孩子的信息。<br />
          2、填写完信息后，每天凌晨零点整时，自动预约开关为开启状态的用户，自动完成当天的乘车预约；自动预约开关为关闭状态的用户，将不会进行当天下午的乘车预约。<br />
          3、每天凌晨00:00~00:30为系统自动预约期，此时无法修改自动预约开关状态。<br />
          4、当天预约乘车是否成功，可以在凌晨00:00后通过当天乘车状态中查看。<br />
          5、当天未开启自动预约开关，临时想要乘车的，可以在当天00:30后点击【我要乘车】进行手动预约。<br />
          6、当天已经预约成功的，可以点击【修改班次】调整当天乘车班次（不影响后续自动预约的班次，自动预约班次始终以用户报名时填写的班次为准）；如果想取消当天的乘车，可以点击【取消乘车】。
        </div>
      </div>
    </v-dialog>
    <v-picker
      :columns="busFrequencyList"
      :valueShow="show"
      valueKey="batchTime"
      @clickOverlay="clickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirm"
    ></v-picker>
  </div>
</template>

<script>
import {
  applyUrl,
  getLastOrderInfoUrl,
  changeStatusUrl,
  getBusFrequencyUrl,
  cancelApplyUrl,
  changeBatchUrl,
  applyAgainUrl,
} from "./api.js";
import { mapState } from "vuex";
import { gloabalCount, toRegister } from "@/utils/common";
export default {
  name: "studyExpertLine",
  data() {
    return {
      show: false,
      showDialog: false,
      disabled: false,
      showBtn: true,
      checked: true,
      signUpStatus: false,
      signNum: 0,
      form: {
        busId: null,
        recordsId: null,
        batchName: null,
        canApply: null,
        batchId: null,
        parentsName: "",
        parentsPhone: "",
        id: "",
        childrenInfoFormList: [],
        weekList: [
          {
            label: "周一车辆班次",
            frequencyId: null,
            batchName: null,
          },
          {
            label: "周二车辆班次",
            frequencyId: null,
            batchName: null,
          },
          {
            label: "周三车辆班次",
            frequencyId: null,
            batchName: null,
          },
          {
            label: "周四车辆班次",
            frequencyId: null,
            batchName: null,
          },
          {
            label: "周五车辆班次",
            frequencyId: null,
            batchName: null,
          },
          {
            label: "周六车辆班次",
            frequencyId: null,
            batchName: null,
          },
          {
            label: "周日车辆班次",
            frequencyId: null,
            batchName: null,
          },
        ],
      },
      editFlag: false,
      busFrequencyList: [], //班次列表
      nowIndex: 0,
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "houseId", "communityId"]),
  },
  created() {
    this.getTime();
  },
  async mounted() {
    gloabalCount("", 84, 1);
    if (await toRegister(this.$route.path, this.$route.query, "热门活动报名")) {
      await this.getLastOrderInfo();
      await this.getBusFrequencyList();
    }
  },
  methods: {
    // 取消乘车
    cancelRegistration() {
      let params = {
        recordsId: this.form.recordsId,
      };
      this.$axios
        .post(`${cancelApplyUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.getLastOrderInfo();
            this.$nextTick(() => {
              this.$toast("取消乘车成功！");
            });
          }
        });
    },
    // 我要乘车
    reEnroll() {
      let params = {
        id: this.form.id,
      };
      this.$axios
        .post(`${applyAgainUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code == 200) {
            this.getLastOrderInfo();
            this.$nextTick(() => {
              this.$toast("操作成功！");
            });
          }
        });
    },
    // 修改班次
    editEnrollment() {
      this.nowIndex = null;
      this.show = true;
    },
    clickOverlay() {
      this.show = false;
    },
    cancelPick() {
      this.show = false;
    },
    confirm(value) {
      if (this.nowIndex == null) {
        let params = {
          recordsId: this.form.recordsId,
          frequencyId: value.frequencyId,
          userId: this.userId,
        };
        this.$axios
          .post(`${changeBatchUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              this.getLastOrderInfo();
              this.$nextTick(() => {
                this.$toast("修改班次成功！");
              });
            }
          });
      } else {
        this.form.weekList[this.nowIndex].frequencyId = value.frequencyId;
        this.form.weekList[this.nowIndex].batchName = value.batchTime;
      }

      this.show = false;
    },
    showPicker(index) {
      this.nowIndex = index;
      this.show = true;
    },
    async getBusFrequencyList() {
      let res = await this.$axios.get(
        `${getBusFrequencyUrl}?tenantId=${this.tenantId}`
      );
      if (res.code == 200) {
        this.form.batchId = res.data[0].batchId;
        this.busFrequencyList = res.data;
      }
    },
    // 提交信息按钮
    submit() {
      if (this.form.parentsName == "") {
        this.$toast("请填写家长姓名！");
        return;
      }
      if (this.form.parentsPhone == "") {
        this.$toast("请填写家长电话号码！");
        return;
      }
      let flag = 1;
      this.form.childrenInfoFormList.forEach((item) => {
        if (item.childrenName == "" || item.childrenClass == "") {
          flag = 2;
        }
      });
      this.form.weekList.forEach((item) => {
        if (item.frequencyId == null || item.batchName == null) {
          flag = 3;
        }
      });
      if (flag == 2) {
        this.$toast("请完善孩子信息！");
        return;
      }
      if (flag == 3) {
        this.$toast("请完善车辆班次！");
        return;
      }
      let params = {
        id: this.form.id,
        parentsName: this.form.parentsName,
        parentsPhone: this.form.parentsPhone,
        userId: this.userId,
        status: Number(this.checked),
        tenantId: this.tenantId,
        childrenInfoFormList: this.form.childrenInfoFormList,
        batchId: this.form.batchId,
        mondayBus: this.form.weekList[0].frequencyId,
        tuesdayBus: this.form.weekList[1].frequencyId,
        wednesdayBus: this.form.weekList[2].frequencyId,
        thursdayBus: this.form.weekList[3].frequencyId,
        fridayBus: this.form.weekList[4].frequencyId,
        saturdayBus: this.form.weekList[5].frequencyId,
        sundayBus: this.form.weekList[6].frequencyId,
      };
      this.$axios.post(`${applyUrl}`, params).then((res) => {
        if (res.code == 200) {
          this.getLastOrderInfo();
        }
      });
    },
    // 获取上次提交信息
    async getLastOrderInfo() {
      let params = {
        userId: this.userId,
        houseId: this.houseId || this.communityId || undefined,
        tenantId: this.tenantId,
      };
      let res = await this.$axios.get(`${getLastOrderInfoUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        if (res.data.childrenInfoFormList.length == 0) {
          this.form.id = res.data.id;
          this.form.canApply = res.data.canApply;
          this.form.parentsName = res.data.parentsName;
          this.form.parentsPhone = res.data.parentsPhone;
          let obj = { id: "", childrenName: "", childrenClass: "" };
          this.form.childrenInfoFormList.push(obj);
          this.signNum = res.data.signNum;
          this.editFlag = true;
        } else {
          this.signNum = res.data.signNum;
          this.form.id = res.data.id;
          this.form.recordsId = res.data.recordsId;
          this.form.busId = res.data.busId;
          this.form.batchName = res.data.batchName;
          this.form.canApply = res.data.canApply;
          this.form.parentsName = res.data.parentsName;
          this.form.parentsPhone = res.data.parentsPhone;
          this.checked = Boolean(res.data.status);
          this.signUpStatus = Boolean(res.data.todayStatus);
          this.form.childrenInfoFormList = res.data.childrenInfoFormList;
          console.log(this.form.childrenInfoFormList);
          // 获取班次列表start
          let obj = res.data.map;
          let list = [];
          for (let k in obj) {
            obj[k].label = this.getLabel(k);
            list.push(obj[k]);
          }
          list.forEach((item) => {
            this.form.weekList.forEach((item1) => {
              if (item1.label == item.label) {
                item1.frequencyId = item.frequencyId;
                item1.batchName = item.batchName;
              }
            });
          });
          console.log(this.form.weekList);
          // 获取班次列表end
          this.editFlag = false;
        }
      }
    },
    getLabel(str) {
      if (str == "Monday") {
        return "周一车辆班次";
      } else if (str == "Tuesday") {
        return "周二车辆班次";
      } else if (str == "Wednesday") {
        return "周三车辆班次";
      } else if (str == "Thursday") {
        return "周四车辆班次";
      } else if (str == "Friday") {
        return "周五车辆班次";
      } else if (str == "Saturday") {
        return "周六车辆班次";
      } else if (str == "Sunday") {
        return "周日车辆班次";
      }
    },
    getTime() {
      let date = new Date();
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (hours == 0 && minutes <= 30) {
        this.disabled = true;
        this.showBtn = false;
      }
    },
    switchChange(value) {
      if (!this.editFlag) {
        let params = {
          id: this.form.id,
          status: Number(value),
        };
        this.$axios
          .post(`${changeStatusUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code == 200) {
              if (Number(value) == 0) {
                this.$toast("关闭自动预约成功！");
              } else {
                this.$toast("开启自动预约成功！");
              }
            }
          });
      } else {
        console.log(value);
      }
    },
    removeItem(index) {
      this.form.childrenInfoFormList.splice(index, 1);
    },
    addOption() {
      let obj = { id: "", childrenName: "", childrenClass: "" };
      this.form.childrenInfoFormList.push(obj);
    },
  },
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 22px 30px 116px;
  background: #f6fafa;
  .empty {
    width: 100%;
    padding-top: 48px;
    .message {
      padding-top: 48px;
      text-align: center;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.25);
      line-height: 40px;
    }
    img {
      width: 572px;
      display: block;
      margin: 0 auto;
    }
  }
  .messageBox {
    padding: 70px 50px;
    box-sizing: border-box;
    position: relative;
    .title {
      font-size: 32px;
      font-weight: 600;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
      padding-bottom: 42px;
    }
    .content {
      font-size: 28px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 46px;
    }
  }
  .banner {
    width: 100%;
    height: 334px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .main-title-box {
    display: flex;
    align-items: center;
    padding: 34px 0 38px;
    box-sizing: border-box;
    .van-switch {
      border: 10px solid #c7d8ff;
    }
    .title {
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-right: 16px;
    }
    .status {
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      margin-left: 20px;
    }
    .txt {
      flex: 1;
      text-align: right;
      font-size: 24px;
      font-weight: 600;
      color: #3a75ff;
    }
    img {
      margin-left: 10px;
      width: 24px;
    }
  }
  .btnsBox {
    width: 100%;
    height: 52px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 64px;
    .cancelBtn {
      width: 144px;
      height: 52px;
      border-radius: 26px;
      border: 2px solid #007eff;
      text-align: center;
      line-height: 52px;
      font-size: 24px;
      color: #007eff;
      margin-left: 34px;
    }
    .editBtn {
      width: 144px;
      height: 52px;
      background: #007eff;
      border-radius: 26px;
      font-size: 24px;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
    }
  }
  .signUpBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .applyAgain {
      width: 144px;
      height: 52px;
      background: #007eff;
      border-radius: 26px;
      font-size: 24px;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
      line-height: 52px;
    }
    .label {
      font-size: 28px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
    }
    .status {
      font-size: 24px;
      margin-left: 16px;
      flex: 1;
      .blue {
        color: #3a75ff;
      }
      .grey {
        color: rgba(0, 0, 0, 0.5);
      }
      .green {
        color: #2bdc70;
      }
    }
  }

  .edit-box {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    .edit-box-left {
      flex: 1;
      text-align: right;
      font-size: 28px;
      color: #2867ce;
    }
    .edit-box-right {
      width: 46px;
      height: 42px;
      margin-left: 18px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .editInfo {
    .inputBox {
      width: 100%;
      height: 88px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
      border-radius: 8px;
      margin-top: 24px;
      display: flex;
      align-items: center;
      position: relative;
      .removeIcon {
        width: 24px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 28px;
      }
      .inputBox-left {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        padding: 0 34px;
        box-sizing: border-box;
      }
      .pr-28 {
        padding-right: 28px;
      }
      .pr-78 {
        padding-right: 78px;
      }
      .inputBox-right {
        flex: 1;
        padding-left: 28px;
        box-sizing: border-box;
        input {
          width: 100%;
          height: 100%;
          display: block;
          text-align: right;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.5);
        }
      }
      .add-left {
        width: 46px;
        height: 46px;
        margin-left: 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .add-right {
        margin-left: 18px;
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
      }
      .left {
        font-size: 28px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        padding-left: 34px;
        width: 190px;
      }
      .center {
        font-size: 24px;
        flex: 1;
        color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-content: center;
        justify-content: space-between;
      }
      .right {
        width: 18px;
        height: 10px;
        margin: 0 12px;
        font-size: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .submitBtn {
      margin-top: 52px;
      height: 96px;
      background: #007eff;
      border-radius: 48px;
      width: 100%;
      text-align: center;
      line-height: 96px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }
  .cheackInfo {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(231, 231, 231, 0.5);
    border-radius: 8px;
    padding: 0 28px 0 32px;
    box-sizing: border-box;
    .item {
      width: 100%;
      height: 110px;
      border-bottom: 2px solid rgba(141, 138, 138, 0.1);
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 40px 0;
      .item-left {
        font-size: 28px;
        width: 190px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        box-sizing: border-box;
      }
      .item-right {
        flex: 1;
        display: flex;
        align-items: center;
        line-height: 1;
        font-size: 24px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>
